import React, { useState } from 'react';
import axios from 'axios';
import './SubscribeForm.css';

const SubscribeForm = ({ onSubmit, setShowConfetti }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    subscriptions: [],
    comments: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData(prevData => ({
        ...prevData,
        subscriptions: checked
          ? [...prevData.subscriptions, value]
          : prevData.subscriptions.filter(item => item !== value)
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: name === 'mobile' ? formatPhoneNumber(value) : value
      }));
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '');
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/subscribe', formData);
      console.log('Subscription form submission response:', response);
      setShowConfetti(true); // Trigger confetti
      onSubmit(formData);
    } catch (error) {
      console.error('Error submitting subscription form:', error.response || error);
    }
  };

  return (
    <form className="subscribe-form" onSubmit={handleSubmit}>
      <h2>Subscribe</h2>

      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input 
          type="text" 
          id="firstName" 
          name="firstName" 
          value={formData.firstName}
          onChange={handleChange}
          placeholder="Al" 
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input 
          type="text" 
          id="lastName" 
          name="lastName" 
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Coholic" 
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email}
          onChange={handleChange}
          placeholder="AlCoholic@moestavern.com" 
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="mobile">Mobile</label>
        <input 
          type="tel" 
          id="mobile" 
          name="mobile" 
          value={formData.mobile}
          onChange={handleChange}
          placeholder="212-555-1212" 
          pattern="\d{3}-\d{3}-\d{4}"
          required 
        />
      </div>

      <div className="form-group checkbox-group">
        <p>Pick your poison:</p>
        <label>
          <input 
            type="checkbox" 
            name="subscriptions" 
            value="Work Tech Weekly"
            checked={formData.subscriptions.includes('Work Tech Weekly')}
            onChange={handleChange}
          />
          Work Tech Weekly
        </label>
        <label>
          <input 
            type="checkbox" 
            name="subscriptions" 
            value="Work Leader Weekly"
            checked={formData.subscriptions.includes('Work Leader Weekly')}
            onChange={handleChange}
          />
          Work Leader Weekly
        </label>
        <label>
          <input 
            type="checkbox" 
            name="subscriptions" 
            value="Propaganda"
            checked={formData.subscriptions.includes('Propaganda')}
            onChange={handleChange}
          />
          Propaganda
        </label>
      </div>

      <div className="form-group">
        <label htmlFor="comments">Comments</label>
        <textarea 
          id="comments" 
          name="comments" 
          value={formData.comments}
          onChange={handleChange}
          placeholder="Could you have Bart deliver this?" 
        ></textarea>
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default SubscribeForm;