import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

const CookieConsent = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      setVisible(false);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="cookie-consent">
      <p>This website uses cookies to ensure you get the best experience.</p>
      <div className="cookie-actions">
        <button onClick={acceptCookies}>Got it!</button>
        <a href="/privacy-policy.html" target="_blank" rel="noopener noreferrer">Learn more</a>
      </div>
    </div>
  );
};

export default CookieConsent;