import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import TSCLogo from './TSC_ocho_logo.png';
import sendButtonIcon from './send_button.svg';
import hamburgerIcon from './hamburger-menu.svg';
import ReactConfetti from 'react-confetti';
import CookieConsent from './CookieConsent';

// Import ContactForm
import ContactForm from './ContactForm';
import SubscribeForm from './SubscribeForm';

const FULL_API_URL = process.env.REACT_APP_FULL_API_URL || window.location.origin;
if (!FULL_API_URL) {
  console.error("FULL_API_URL is not defined and window.location.origin is unavailable.");
}

console.log('API URL:', FULL_API_URL);

function App() {
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: "What's up, Buttercup? Welcome to TSC! We used to be The Starr Conspiracy, but hey ... you know ... change and stuff. Before you ask, yes ... this is our real website. I'm chaDbot. I'm a lot like a chatbot, but lazier and less reliable 🥱🤷. Click on the menu in the upper right-hand corner for a few conversation starters. Or just start typing and see what happens!",
      timestamp: new Date().toISOString(),
    },
  ]);
  const [input, setInput] = useState('');
  const [sessionId] = useState(uuidv4());
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);
  const contactFormRef = useRef(null);
  const [showContactForm, setShowContactForm] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesRef = useRef(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const menuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const inputRef = useRef(null);
  const [showSubscribeForm, setShowSubscribeForm] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const adjustContainerHeight = () => {
    if (containerRef.current) {
      const windowHeight = window.innerHeight;
      containerRef.current.style.height = `${windowHeight - 20}px`;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    adjustContainerHeight();
    window.addEventListener("resize", adjustContainerHeight);
    return () => window.removeEventListener("resize", adjustContainerHeight);
  }, []);

  useEffect(() => {
    if (showContactForm && contactFormRef.current) {
      contactFormRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [showContactForm]);

  useEffect(() => {
    const handleScroll = () => {
      if (messagesRef.current) {
        const messages = messagesRef.current.querySelectorAll('.user-message, .assistant-message');
        const logoStripe = document.querySelector('.logo-stripe');
        if (logoStripe) {
          const logoBottom = logoStripe.getBoundingClientRect().bottom;

          messages.forEach(message => {
            const messageTop = message.getBoundingClientRect().top;
            if (messageTop < logoBottom) {
              message.classList.add('scrolled');
            } else {
              message.classList.remove('scrolled');
            }
          });
        }
      }
    };

    const messagesContainer = messagesRef.current;
    if (messagesContainer) {
      messagesContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (messagesContainer) {
        messagesContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    // Focus the input field on component mount
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => setShowConfetti(false), 50000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  const handleSend = async () => {
    if (input.trim() === '') return;

    setMessages([...messages, { role: "user", content: input }]);
    setInput('');
    setIsTyping(true);

    try {
      const response = await axios.post(`${FULL_API_URL}/api/chat`, { query: input, sessionId });
      setIsTyping(false);
      setMessages(prevMessages => [...prevMessages, { role: "assistant", content: response.data.reply }]);
    } catch (error) {
      console.error('Error:', error);
      setIsTyping(false);
      setMessages(prevMessages => [...prevMessages, { role: "assistant", content: 'Sorry, there was an error processing your request.' }]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const renderMessageContent = (msg) => {
    if (msg.role === "assistant" && msg.content === "SUBSCRIBE_FORM") {
      return (
        <SubscribeForm
          onSubmit={(formData) => {
            console.log('Subscription submitted:', formData);
            setMessages(prevMessages => [
              ...prevMessages,
              {
                role: "assistant",
                content: "🎉 Thanks for subscribing! I know some senior analyst types who are going to be tickled pink.",
              },
            ]);
          }}
          setShowConfetti={setShowConfetti}
        />
      );
    }
    if (msg.role === "assistant" && msg.content === "CONTACT_FORM") {
      return (
        <ContactForm
          onSubmit={(formData) => {
            console.log(formData);
            setShowContactForm(false);
            setMessages(prevMessages => [...prevMessages, { 
              role: "assistant", 
              content: "🎉 🎊 Got it! I'll rattle some cages and get someone to contact you. I mean, I'll try my best, you know? Who can get these folks to do anything?",
            }]);
          }}
          setShowConfetti={setShowConfetti}
        />
      );
    }
    if (msg.type === "video") {
      return (
        <video 
          src={msg.content} 
          controls 
          style={{ maxWidth: '100%', borderRadius: '8px' }}
        >
          Your browser does not support the video tag.
        </video>
      );
    }
    return msg.role === "assistant" ? (
      <div dangerouslySetInnerHTML={{ __html: msg.content }} />
    ) : (
      <div>{msg.content}</div>
    );
  };

  const handleContactMe = () => {
    setMessages([...messages, { role: "user", content: "Have someone contact me." }]);
    setIsTyping(true);
    setTimeout(() => {
      setMessages(prevMessages => [...prevMessages, { role: "assistant", content: "I got you 100%. Let me take down your information and I'll have someone from TSC reach out." }]);
      setIsTyping(false);
      setTimeout(() => {
        setShowContactForm(true);
      }, 1000);
    }, 1000);
    setIsMenuActive(false);
  };

  const handleWhoIsTSCClick = () => {
    setMessages(prevMessages => [...prevMessages, { role: "user", content: "Who is TSC?" }]);

    const assistantMessages = [
      "TSC is a full stack agency that is redefining marketing for tech companies—because the blocking and tackling era is over, and the road to hell 👿👿 is paved with performance optimizations.",
      "We break the script to create defining moments and definitive experiences that cement vendor preference. So jump in. It's time to burn playbooks, and we just bought a fireworks factory next to a fertilizer plant. 🔥🔥🔥🔥🔥🔥",
      "They told me to say all that. Please help. 🆘 🆘 🆘 "
    ];

    setTimeout(() => {
      assistantMessages.forEach((message, index) => {
        setTimeout(() => {
          setIsTyping(true);
          setTimeout(() => {
            setIsTyping(false);
            setMessages(prevMessages => [...prevMessages, { role: "assistant", content: message }]);
          }, 750);
        }, index * 1500);
      });
    }, 500);
    setIsMenuActive(false);
  };

  const handleServicesClick = () => {
    setMessages(prevMessages => [...prevMessages, { role: "user", content: "What services does TSC provide?" }]);

    const assistantMessages = [
      "TSC is a full stack marketing agency for tech companies. We create brand, marketing, sales and product experiences rooted in holistic customer experience.",
      "We are best known for strategy, experience design, research, AI enablement, brand, message, marketing, content, design, digital and public relations.",
      "Since 1999, we've helped thousands of tech companies find their special slice of the market. 🍕🍕🍕"
    ];

    setTimeout(() => {
      assistantMessages.forEach((message, index) => {
        setTimeout(() => {
          setIsTyping(true);
          setTimeout(() => {
            setIsTyping(false);
            setMessages(prevMessages => [...prevMessages, { role: "assistant", content: message }]);
          }, 750);
        }, index * 1500);
      });
    }, 500);
    setIsMenuActive(false);
  };

  const handleSubscribeClick = () => {
    setMessages([...messages, { role: "user", content: "I would like to subscribe." }]);
    setIsTyping(true);
    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: "assistant",
          content: "Thanks for caring about TSC content! ❤️ Fill out this form (sorry) to set your content preferences.",
        },
      ]);
      setIsTyping(false);
      setShowSubscribeForm(true);
    }, 1000);
    setIsMenuActive(false);
  };

  const handleWorkClick = () => {
    setMessages(prevMessages => [
      ...prevMessages,
      { role: "user", content: "Show me that sizzle reel!" }
    ]);
    setIsTyping(true);
    setTimeout(() => {
      setMessages(prevMessages => [
        ...prevMessages,
        {
          role: "assistant",
          type: "video",
          content: "/videos/TSC_sizzle_reel.mp4"
        }
      ]);
      setIsTyping(false);
    }, 1000);
    setIsMenuActive(false);
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) && !hamburgerRef.current.contains(event.target)) {
        setIsMenuActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="App">
      <div className="content-container">
        <div className="chat-container" ref={containerRef}>
          <img
            src={TSCLogo}
            alt="TSC Logo"
            className="logo"
            loading="lazy"
          />

          <div
            className="hamburger-menu"
            onClick={toggleMenu}
            ref={hamburgerRef}
            role="button"
            aria-label="Open menu"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                toggleMenu();
              }
            }}
          >
            <img
              src={hamburgerIcon}
              alt="Menu"
              loading="lazy"
            />
          </div>

          {isMenuActive && (
            <nav className="menu-content" ref={menuRef}>
              <div
                className="prompt-box"
                onClick={handleWhoIsTSCClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleWhoIsTSCClick();
                  }
                }}
              >
                <strong>Who is TSC?</strong>
                <p>Give me the short version.</p>
              </div>
              <div
                className="prompt-box"
                onClick={handleServicesClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleServicesClick();
                  }
                }}
              >
                <strong>Services</strong>
                <p>What does TSC do for a living?</p>
              </div>
              <div
                className="prompt-box"
                onClick={handleContactMe}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleContactMe();
                  }
                }}
              >
                <strong>Contact me</strong>
                <p>Have someone from TSC reach out.</p>
              </div>
              <div
                className="prompt-box"
                onClick={handleSubscribeClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubscribeClick();
                  }
                }}
              >
                <strong>Subscribe</strong>
                <p>Sign up for TSC content.</p>
              </div>
              <div
                className="prompt-box"
                onClick={() => {
                  window.open('https://www.linkedin.com/company/the-starr-conspiracy/?viewAsMember=true', '_blank');
                  setIsMenuActive(false);
                }}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    window.open('https://www.linkedin.com/company/the-starr-conspiracy/?viewAsMember=true', '_blank');
                    setIsMenuActive(false);
                  }
                }}
              >
                <strong>LinkedIn</strong>
                <p>Take me to your socials.</p>
              </div>
              <div
                className="prompt-box"
                onClick={handleWorkClick}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleWorkClick();
                  }
                }}
              >
                <strong>Work</strong>
                <p>Show me that sizzle reel!</p>
              </div>
            </nav>
          )}

          <div className="messages" ref={messagesRef}>
            <CookieConsent />
            {messages.map((message, index) => (
              <div key={index} className={`message ${message.role === "user" ? "user-message" : "assistant-message"}`}>
                {renderMessageContent(message)}
              </div>
            ))}
            {showSubscribeForm && (
              <SubscribeForm
                onSubmit={(formData) => {
                  console.log('Subscription submitted:', formData);
                  setShowSubscribeForm(false);
                  setMessages(prevMessages => [
                    ...prevMessages,
                    {
                      role: "assistant",
                      content: "🎉 Thanks for subscribing! We'll keep you updated with our latest content.",
                    },
                  ]);
                }}
                setShowConfetti={setShowConfetti}
              />
            )}
            {isTyping && (
              <div className="typing-indicator">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </div>
            )}
            {showContactForm && !isTyping && (
              <div className="assistant-message" ref={contactFormRef}>
                {renderMessageContent({ role: "assistant", content: "CONTACT_FORM" })}
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div className="input-container">
            <textarea
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Start typing ..."
              className="message-input"
              rows="1"
              style={{ lineHeight: 'normal' }}
            />
            <button
              className="send-arrow"
              onClick={handleSend}
              aria-label="Send message"
            >
              <img src={sendButtonIcon} alt="Send" />
            </button>
          </div>
        </div>
      </div>
      {showConfetti && (
  <ReactConfetti
    width={window.innerWidth}
    height={window.innerHeight}
    numberOfPieces={3000} 
    gravity={0.5}
    colors={['#ed0ad2', '#73f5ff', '#ff5910', '#e1ff00']} // Use your brand colors
    recycle={false}
  />
)}
    </div>
  );
}

export default App;