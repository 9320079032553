import React, { useState } from 'react';
import axios from 'axios';
import './ContactForm.css';

const ContactForm = ({ onSubmit, setShowConfetti }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    topic: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'mobile' ? formatPhoneNumber(value) : value
    }));
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/\D/g, '');
    const match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/contact', formData);
      console.log('Form submission response:', response);
      setShowConfetti(true); // Trigger confetti
      onSubmit(formData);
    } catch (error) {
      console.error('Error submitting form:', error.response || error);
    }
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h2>Contact Me</h2>

      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input 
          type="text" 
          id="firstName" 
          name="firstName" 
          value={formData.firstName}
          onChange={handleChange}
          placeholder="Anita" 
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input 
          type="text" 
          id="lastName" 
          name="lastName" 
          value={formData.lastName}
          onChange={handleChange}
          placeholder="Bath" 
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input 
          type="email" 
          id="email" 
          name="email" 
          value={formData.email}
          onChange={handleChange}
          placeholder="anitabath@moestavern.com" 
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="mobile">Mobile</label>
        <input 
          type="tel" 
          id="mobile" 
          name="mobile" 
          value={formData.mobile}
          onChange={handleChange}
          placeholder="212-555-1212" 
          pattern="\d{3}-\d{3}-\d{4}"
          required 
        />
      </div>

      <div className="form-group">
        <label htmlFor="topic">Topic</label>
        <textarea 
          id="topic" 
          name="topic" 
          value={formData.topic}
          onChange={handleChange}
          placeholder="I heard Bart was looking for me." 
          required
        ></textarea>
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
